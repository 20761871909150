<template>
  <el-card class="main">
    <div class="panel-items">
      <div class="panel-item">
        <div class="header">
          <span>项目名称</span>
        </div>
        <div class="item" :class="{ active: item.projectId === currentProjectId }" v-for="(item, index) of projectList"
          :key="index" @click="projectChange(item)">
          <span>{{ item.projectName }}</span>
          <img src="@/assets/img/arrow-right.png" />
        </div>
      </div>
      <div class="panel-item">
        <div class="header">
          <span>区域名称</span>
        </div>
        <div class="item" :class="{ active: item.projectAreaId == currentAreaId }" v-for="(item, index) of areaList"
          :key="index" @click="areaChange(item)">
          <span>{{ item.projectAreaName }}</span>
        </div>
      </div>
    </div>

    <div id="map"></div>

    <div class="info">
      <div class="left">
        <div class="item" :class="{ active: tabIndex === 1 }" @click="tabIndex = 1">
          <span>养护视图</span>
        </div>
        <div class="item" :class="{ active: tabIndex === 2 }" @click="tabIndex = 2">
          <span>养护标准</span>
        </div>
      </div>
      <div class="right">
        <div class="header">
          <span>{{ currentAreaName }}</span>
        </div>
        <div class="content" v-if="tabIndex === 1">
          <div class="line1">
            <div class="left1">
              <div class="item">
                <span class="value">{{ yhst.issueCount || 0 }}</span>
                <div>
                  <span>上报问题</span>
                </div>
              </div>
              <div class="item">
                <span class="value error">{{ yhst.issueCount || 0 }}</span>
                <div>
                  <span>待整改</span>
                </div>
              </div>
            </div>
            <div class="right1">
              <el-carousel v-if="yhst.pointUploadList && yhst.pointUploadList.length" :autoplay="false" indicator-position="none" :interval="5000"
                :arrow="yhst.pointUploadList.length > 5 ? 'hover' : 'never'">
                <el-carousel-item v-for="item in Math.ceil(yhst.pointUploadList.length / 5)" :key="item">
                  <div class="img-items">
                    <div class="img-item"
                      v-for="(sign, index) of yhst.pointUploadList.slice((item - 1) * 5, ((item - 1) * 5 + 5))"
                      :key="index">
                      <div class="img-header">
                        <span>{{ sign.facilityName }}</span>
                      </div>
                      <div class="sign-info">
                        <span>{{ sign.userName }}</span>
                        <span>{{ sign.ctime.split(' ')[1] }}</span>
                      </div>
                      <el-image class="img" fit="cover"
                        :preview-src-list="[sign.fileUpload && sign.fileUpload.filePrefix + sign.fileUpload.fileUrl]"
                        :src="sign.fileUpload && sign.fileUpload.filePrefix + sign.fileUpload.fileUrl"></el-image>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <el-empty :image-size="50" v-else description="该日暂无数据"></el-empty>
            </div>
          </div>
          <div class="line2">
            <el-dropdown trigger="click">
              <div class="month">
                <span>{{ currentMonth }}</span>
                <i class="el-icon-caret-bottom"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="initMonth(i)" v-for="i in maxMonth" :key="i">{{ i }}月</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="sjz">
              <div class="item" @click="changeDate(item)" :id="'id' + item" :class="{ active: item === currentDay }"
                v-for="(item, index) of dayArr" :key="index">
                <img v-if="item === currentDay" src="@/assets/img/sigin.svg" />
                <img v-else src="@/assets/img/unsign.svg" />
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content2" v-if="tabIndex === 2">
          <div class="left3">
            <div class="line1">
              <span>养护等级</span>
              <span>{{ yhbz.conserveLevel || '--' }}</span>
            </div>
            <div class="line2">
              <img :src="userAvatar" />
              <div class="user">
                <span>{{ yhbz.liableUserName || '--' }}</span>
                <span>区域负责人</span>
              </div>
            </div>
          </div>
          <div class="right3">
            <div class="item">
              <div class="right-header">
                <span>标准描述</span>
              </div>
              <div class="right-item">
                <div v-if="yhbz.conserveDesc" class="note">{{ yhbz.conserveDesc }}</div>
                <el-empty :image-size="30" v-else description="暂无数据"></el-empty>
              </div>
            </div>
            <div class="item">
              <div class="right-header">
                <span>重点区域</span>
              </div>
              <div class="right-item">
                <template v-if="yhbz.conserveElementMap && yhbz.conserveElementMap.length">
                  <div class="tabs">
                    <div class="tab-item" @click="bzTabIndex = index" :class="{ active: bzTabIndex === index }"
                      v-for="(item, index) of yhbz.conserveElementMap" :key="index">
                      <span>{{ item.key }}</span>
                    </div>
                  </div>
                  <div class="note">{{ yhbz.conserveElementMap[bzTabIndex] && yhbz.conserveElementMap[bzTabIndex].value.map(item =>
                    item.standardContent).join('\n') }}</div>
                </template>
                <el-empty :image-size="30" v-else description="暂无数据"></el-empty>
              </div>
            </div>
            <div class="item">
              <div class="right-header">
                <span>标准示范</span>
              </div>
              <div class="right-item">
                <el-carousel v-if="yhbz.conservePointList.length" :autoplay="true" indicator-position="none"
                  :interval="5000" :arrow="yhbz.conservePointList.length > 2 ? 'hover' : 'never'">
                  <el-carousel-item v-for="item in Math.ceil(yhbz.conservePointList.length / 2)" :key="item">
                    <div class="img-items">
                      <div class="img-item"
                        v-for="(sign, index) of yhbz.conservePointList.slice((item - 1) * 2, ((item - 1) * 2 + 2))"
                        :key="index">
                        <el-image class="img" fit="cover"
                          :preview-src-list="[sign.fileUpload && sign.fileUpload.filePrefix + sign.fileUpload.fileUrl]"
                          :src="sign.fileUpload && sign.fileUpload.filePrefix + sign.fileUpload.fileUrl">
                          <div slot="error" class="el-image__error">
                            暂无图片
                          </div>
                        </el-image>
                        <div class="img-header">
                          <span>{{ sign.objectName }}</span>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <el-empty :image-size="30" v-else description="暂无数据"></el-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import defaultAvatar from '@/assets/img/picture.png'
export default {
  name: 'ProjectGrid',
  data () {
    return {
      tabIndex: 1,
      bzTabIndex: 0,
      projectList: [],
      areaList: [],
      currentProjectId: '',
      currentAreaId: '',
      currentAreaName: '',
      currentMonth: '',
      currentDay: '',
      dayArr: [],

      yhst: {
        dzgIssueCount: 0,
        issueCount: 0,
        pointUploadList: []
      },
      yhbz: {
        liableUserImg: {},
        conserveLevel: '',
        liableUserName: '',
        conserveElementMap: [],
        conservePointList: [],
        conserveDesc: ''
      },
      maxMonth: 0
    }
  },
  computed: {
    userAvatar () {
      if (this.yhbz.liableUserImg) {
        return this.yhbz.liableUserImg.filePrefix + this.yhbz.liableUserImg.fileUrl
      } else {
        return defaultAvatar
      }
    }
  },
  watch: {
  },
  created () {
    // do something
    this.maxMonth = +this.$dayjs().format('M')
  },
  mounted () {
    this.loadMap()
    this.currentDay = this.$dayjs().format('MM-DD')
    this.initMonth(this.$dayjs().month() + 1)
    this.$nextTick(() => {
      document.querySelector('#id' + this.currentDay).scrollIntoView({ behavior: 'smooth', inline: 'center' })
    })
  },
  methods: {
    loadSignData () {
      this.$axios.get(this.$apis.project.selectProjectAreaYhDatePoint, {
        projectAreaId: this.currentAreaId,
        cDate: this.currentDay.replace('-', '.')
      }).then(res => {
        this.yhst = res || {}
      })
    },
    loadAreaYhStandard () {
      this.$axios.get(this.$apis.project.selectProjectAreaYhStandard, {
        projectAreaId: this.currentAreaId
      }).then(res => {
        const conserveElementMap = []
        for (const key in res.conserveElementMap) {
          conserveElementMap.push({
            key: key,
            value: res.conserveElementMap[key]
          })
        }
        res.conserveElementMap = conserveElementMap
        this.yhbz = res
      })
    },
    loadMap () {
      AMapLoader.load({
        key: mapKey,
        version: '2.0',
        plugins: []
      }).then(() => {
        this.map = new AMap.Map('map', {
          zoom: 11,
          doubleClickZoom: false
        })
        this.loadProjectList()
        // this.markerListAdd()
        // this.isChange && this.map.on('click', (e) => {
        //   this.markerClick([e.lnglat.getLng(), e.lnglat.getLat()])
        // })
      })
    },
    loadProjectList () {
      this.$axios.get(this.$apis.project.selectProjectAreaYhList).then(res => {
        this.projectList = res
        this.projectChange(res[0])
        this.areaChange(res[0].projectAreaList[0])
      })
    },
    projectChange (item) {
      this.currentProjectId = item.projectId
      this.areaList = item.projectAreaList
    },
    areaChange (item) {
      this.currentAreaId = item.projectAreaId
      this.currentAreaName = item.projectAreaName
      this.getProjectLocation(this.currentAreaId)
      this.currentDay = this.$dayjs().format('MM-DD')
      this.loadSignData()
      this.loadAreaYhStandard()
    },
    initMonth (month) {
      console.log('什么月', month)
      const dayArr = []
      this.currentMonth = month + '月'
      const days = +this.$dayjs().month(month - 1).endOf('month').format('D')
      for (let index = 1; index <= days; index++) {
        dayArr.push(`${String(month).padStart(2, 0)}-${String(index).padStart(2, 0)}`)
      }
      this.dayArr = dayArr
    },
    changeDate (date) {
      this.currentDay = date
      this.loadSignData()
      document.querySelector('#id' + this.currentDay).scrollIntoView({ behavior: 'smooth', inline: 'center' })
    },
    // 获取项目电子围栏
    getProjectLocation () {
      console.log('执行了电子围栏接口')
      this.$axios.get(this.$apis.project.selectProjectAreaById, {
        projectId: this.currentProjectId,
        projectAreaId: this.currentAreaId
      }).then((res) => {
        this.map.clearMap()
        this.ploygons = drawPolygon(this.map, res.areaLocation, null, (e) => {
          // this.isChange && this.markerClick([e.lnglat.getLng(), e.lnglat.getLat()])
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 100%;
  position: relative;

  ::v-deep .el-card__body {
    height: 100%;
  }
}

#map {
  width: 100%;
  height: 100%;
}

.panel-items {
  position: absolute;
  top: 56px;
  left: 40px;
  width: 280px;
  background: #FFFFFF;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  z-index: 100;
  display: flex;

  .panel-item {
    flex: 1;
    flex-shrink: 0;

    &:nth-child(2) {
      border-left: 1px solid #EEEEEE;
    }

    .header {
      height: 32px;
      background: rgba(26, 209, 176, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
    }

    .item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      cursor: pointer;

      &:hover {
        background-color: #f9f9f9;
      }

      &.active {
        span {
          font-weight: 500;
          color: #1AD1B0;
        }
      }

      span {
        flex: 1;
        width: 0;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 10px;
        text-align: center;
      }

      img {
        position: absolute;
        width: 10px;
        height: 10px;
        right: 4px;
        top: 11px;
      }
    }
  }
}

.info {
  position: absolute;
  bottom: 40px;
  left: 40px;
  right: 40px;
  display: flex;
  align-items: center;

  .left {
    display: flex;
    flex-direction: column;

    .item {
      width: 32px;
      height: 90px;
      background: #F4F7FA;
      border: 1px solid #DBDFE6;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.active {
        background-color: #1AD1B0;

        span {
          color: #FFFFFF;
        }
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
        padding: 10px;
      }
    }
  }

  .right {
    flex: 1;
    height: 358px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    .header {
      height: 44px;
      background: #F4F7FA;
      display: flex;
      align-items: center;
      padding: 0 20px;
      flex-shrink: 0;

      span {
        font-size: 18px;
        font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      flex: 1;

      .line1 {
        flex: 1;
        display: flex;
        align-items: center;
        height: 292px;

        .left1 {
          width: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 30px 0;

          .item {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:nth-child(2) {
              margin-top: 10px;
            }

            .value {
              font-size: 36px;
              font-family: DINAlternateBold;
              font-weight: bold;
              color: #333333;
              line-height: 36px;

              &.error {
                color: #FF603B;
              }
            }

            div {
              margin-top: 10px;
              width: 150px;
              height: 46px;
              background: linear-gradient(132deg, #FFFFFF 0%, #DEECFF 100%);
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
              }
            }
          }
        }

        .right1 {
          flex: 1;
          height: 248px;

          .el-carousel {
            height: 248px;
          }

          ::v-deep .el-carousel__container {
            height: 248px;
          }

          .img-items {
            padding: 10px 24px;
            display: flex;

            .img-item {
              width: 232px;
              height: 228px;
              background: #FFFFFF;
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
              border-radius: 4px;
              border: 1px solid #659DB1;
              display: flex;
              align-items: center;
              flex-direction: column;

              &:nth-child(n + 2) {
                margin-left: 20px;
              }

              .img-header {
                height: 52px;
                // background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(26, 76, 94, 0.72) 25%, rgba(42, 97, 117, 0.8) 50%, rgba(20, 69, 86, 0.69) 76%, rgba(0, 0, 0, 0.4) 100%);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background: url(../../assets/img/sign-bg.png) no-repeat;
                background-size: 232px 52px;

                span {
                  font-size: 16px;
                  font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
                  font-weight: bold;
                  color: #FFFFFF;
                  line-height: 22px;
                }
              }

              .sign-info {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;

                span {
                  &:nth-child(1) {
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 16px;
                  }

                  &:nth-child(2) {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 16px;
                    margin-left: 10px;
                  }
                }
              }

              .img {
                width: 192px;
                height: 108px;
                border-radius: 20px;
              }
            }
          }
        }
      }

      .line2 {
        height: 66px;
        flex-shrink: 0;
        display: flex;
        padding: 0 20px;

        .month {
          width: 90px;
          height: 36px;
          background: #659DB1;
          border: 1px solid #DBDFE6;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 18px;
            margin-right: 4px;
          }

          i {
            color: #FFFFFF;
            margin-bottom: 4px;
            font-size: 14px;
          }
        }

        .sjz {
          width: 0;
          flex: 1;
          display: flex;
          overflow-x: auto;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
            /* 设置滚动条的宽度 */
          }

          .item {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80px;
            flex-shrink: 0;

            &.active {
              span {
                color: #1EA2FF;
              }
            }

            img {
              width: 24px;
              height: 24px;
            }

            span {
              font-size: 14px;
              font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
              font-weight: bold;
              color: #B9BBBE;
              line-height: 16px;
              margin-top: 2px;
            }
          }
        }
      }
    }

    .content2 {
      display: flex;
      flex: 1;
      align-items: center;

      .left3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        padding-left: 20px;

        .line1 {
          width: 218px;
          height: 78px;
          background: linear-gradient(90deg, #FED591 0%, #FFFBEB 70%);
          border-radius: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 30px;

          span {
            &:nth-child(1) {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 22px;
              margin-right: 10px;
            }

            &:nth-child(2) {
              font-size: 48px;
              font-family: YouSheBiaoTiHei;
              color: #1AD1B0;
              line-height: 62px;
              text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
            }
          }
        }

        .line2 {
          display: flex;
          align-items: center;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-right: 13px;
          }

          .user {
            display: flex;
            flex-direction: column;

            span {
              &:nth-child(1) {
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 28px;
              }

              &:nth-child(2) {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 12px;
              }
            }
          }
        }
      }

      .right3 {
        height: 314px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding: 20px 40px;

        .item {
          display: flex;
          flex-direction: column;
          width: 388px;
          background: #FFFFFF;
          box-shadow: 0px -4px 20px -10px rgba(0, 0, 0, 0.2);
          border: 1px solid #EAEEF5;
          overflow: hidden;
          border-radius: 8px;

          .right-item {
            flex: 1;
            padding: 20px;
            display: flex;
            flex-direction: column;

            .note {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              line-height: 22px;
              display: flex;
              flex-direction: column;
              white-space: pre-line;
            }

            .tabs {
              display: inline-flex;
              height: 32px;
              background: #F7F8FA;
              border-radius: 30px;
              padding: 2px;
              align-self: center;
              margin-bottom: 20px;

              .tab-item {
                width: 80px;
                height: 28px;
                border-radius: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                padding: 0 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.active {
                  background: #FFFFFF;
                  box-shadow: 0px 2px 2px 0px rgba(195, 195, 195, 0.5);

                  span {
                    color: #484A59;
                  }
                }

                span {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #92A0B6;
                  line-height: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }

            .el-carousel {
              height: 190px;
            }

            ::v-deep .el-carousel__container {
              height: 190px;
            }

            .img-items {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .img-item {
                position: relative;
                width: 164px;
                height: 192px;

                .img {
                  width: 164px;
                  height: 192px;
                }

                .img-header {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  height: 34px;
                  background: rgba(0, 0, 0, 0.59);
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  span {
                    font-size: 14px;
                    font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 22px;
                  }
                }
              }
            }
          }

          .right-header {
            height: 44px;
            background: #719EAE;
            border: 1px solid #EAEEF5;
            display: flex;
            align-items: center;
            padding: 0 20px;

            span {
              font-size: 18px;
              font-family: YouSheBiaoTiHei;
              color: rgba(255, 255, 255, 0.85);
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
